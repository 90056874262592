$modal-bg-color: #262626;
$modal-content-bg-color: #779e0e;
$content-bg-color: #d0c182;
$modal-content-padding: 1.25rem;
$modal-content-border-radius: 5px;
$modal-content-max-width: 31.25rem;
$font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
$min-height: 100vh;
$spacing: 1rem;
$spacing: 1rem;
$border: 2px solid #000;

.modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $modal-bg-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;
  height: 30%;
  border: $border;
  padding: $modal-content-padding;
  border-radius: $modal-content-border-radius;

  &.modal-coordinates {
    height: 45%;
  }
  
  .input-modal, .select-modal, .button-modal {
    color: $modal-content-bg-color;
    background-color: $modal-bg-color;
    border: $border;
    margin: $spacing;
    padding: $spacing;
    font-weight: bold;
    font-family: $font-family;
    border-radius: $modal-content-border-radius;
    cursor: pointer;
  }
  
  .button-modal {
    &:hover {
      background-color: $modal-content-bg-color;
      color: $modal-bg-color;
    }
  
    &[disabled] {
      color: rgb(152, 11, 11);
      cursor: not-allowed;
    }
  }
  
  .span-modal {
    font-weight: bold;
    font-family: $font-family;
    color: $modal-content-bg-color;
  }
}

@media (max-width: 768px) {
  .modal {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .modal {
    width: 50%;
    height: 40%;
  }
}