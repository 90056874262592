@import 'global';
$spacing-md: 1rem;
$spacing-lg: 2rem;

.section-container {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 20px;
  margin-top: $spacing-lg;
  padding-top: $spacing-md;
}

.aside-container {
  display: grid;
  grid-template-rows: 1fr;
  gap: 10px;
  margin-left: $spacing-md;
  padding: $spacing-md;
}

.aside-container h1, .aside-container h2,
.aside-container p, .aside-container span {
  font-weight: bold;
  font-family: $font-family;
}

.main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: $spacing-md;
  margin-right: $spacing-md;
  padding-bottom: $spacing-md;
  padding-right: $spacing-md;

  .media-content {
    margin-right: $spacing-md;

    img, video {
      width: 100%;
      height: auto;
      border: 2px solid $background-color;
    }
  }

  .media-content:first-child {
    margin-top: 3rem;
  }

  .text-content p {
    text-align: center;
    font-weight: bold;
    font-family: $font-family;
  }

  .text-content:last-child {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .section-container {
    grid-template-columns: 1fr;
  }
  
  .main-container {
    grid-row: 2;
    grid-template-columns: 1fr;
    margin: $spacing-md;
    padding: $spacing-md;

    .media-content {
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
}

@media (max-width: 1300px) {
  .main-container .text-content:last-child {
    display: flex;
    align-items: flex-start;
  }
}