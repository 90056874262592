@import 'global';
$font-size-lg: 1.5rem;
$spacing-lg: 5rem;
$spacing-md: 1rem;
$spacing-sm: 0.5rem;

.section-bottom-page {
  display: flex;
  gap: 20px;
  margin-top: 2rem;
  padding-bottom: 2.5rem;

  .section-aside-inner-group, 
  .section-main-inner-group {
    gap: 10px;
    display: flex;
    margin: $spacing-sm;
    padding: $spacing-sm;

    .text-size {
      display: flex;  
      text-align: center; 
      font-weight: bold;
      font-family: $font-family;
      margin-top: $spacing-md;
    }
  }

  .section-main-inner-group {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    
    .main-image {
      max-width: 50%;
      height: auto;
    }
  }
  
  .section-aside-inner-group {
    display: flex;
    flex-direction: column;

    .first-aside-image {
      max-width: 75%;
      height: auto;
      margin: 0 auto;
    }
    
    .second-aside-image {
      max-width: 100%; 
      height: auto;
    }
  }
}

.faq-list {
  display: flex;
  flex-direction: column;

  .faq-item {
    width: 100%;
    font-weight: bold;
    font-family: $font-family;
    padding: 2rem 2rem 0;

    &:first-child {
      padding-top: $spacing-lg;
    }
      
    &:last-child {
      padding-bottom: $spacing-lg;
    }

    h3 {
      font-size: $font-size-lg;
      margin-bottom: $spacing-md;  
    }

    p {
      font-size: $font-size;
      margin-bottom: $spacing-md;
    }

    ul {
      list-style-type: disc;
      margin-left: $spacing-md;
    }

    li {
      margin-bottom: $spacing-sm;
    }
  }
}  

@media (max-width: 1024px) {
  .main-image {
    max-width: 75%;
  }
  .first-aside-image {
    max-width: 100%;
  }
}