@import '~scss-reset/reset';

$background-color: #262626;
$text-color: #d0c182;
$link-color: #779e0e;
$font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
$size: 2.5rem;
$font-size: 1rem;
$line-height: 1.5;
$default-margin-bottom: 1.25rem;
$carbon-icon-size: 3rem;
$carbon-icon-hover-scale: 1.1;

body {
  background-color: $background-color;
  color: $text-color;
}

.page-container {
  max-width: 1300px;
  width: 100%;
  margin: auto;
}

p {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  margin-bottom: $default-margin-bottom;
}

ul {
  list-style: none;
}

.app-name, .link {
  font-weight: bold;
  color: $link-color;
}

.link {
  text-decoration: none;
}

.logo-container {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.carbon-icon-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
}

.carbon-icon {
  margin-top: 2rem;
  padding-top: 2rem;
  font-size: $carbon-icon-size;
  height: auto;
  max-width: 100%;
  transition: color 0.3s ease-in-out;

  &:hover {
    transform: scale($carbon-icon-hover-scale);
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333;
  border-radius: 50%;
  width: $size;
  height: $size;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  body {
    position: absolute;
    top: 10%;
  }
}