$font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
$font-size-lg: 2rem;
$font-size-md: 1.25rem;
$font-size-sm: 1rem;
$spacing-lg: 5rem;
$spacing-md: 2.5rem;
$spacing-sm: 0.625rem;
$link-color: #d0c182;
$icon-color: #779e0e;
$background-dark: #262626;
$background-light: #ccc;

.footer {
  position: relative;
  width: 100%;
  padding: 0;
  border-top: 1px solid #000;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: $background-dark;
    z-index: -1;
  }
  
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: $background-light;
    z-index: -1;
  }
}

.footer-content {
  position: relative;
  padding: $spacing-sm;
  color: $link-color;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $background-dark;

  .link-italic {
    font-style: italic;
    text-decoration: none;
    font-weight: bold;
    color: $link-color;
  }

  .footer-content-text {
    font-weight: bold;
    font-family: $font-family;
    font-size: $font-size-md;
  }
}

.footer-list {
  position: relative;
  padding: $spacing-sm;
  display: flex;
  justify-content: center;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;

    li {
      font-size: $font-size-md;

      .icon {
        font-size: $font-size-lg;
        color: $icon-color;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer-content {
    padding-bottom: 0;

    .footer-content-text {
      font-size: $font-size-sm;
    }
  }
}