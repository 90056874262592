$background-light: #ccc;
$background-dark: #262626;
$size: 1.125rem;
$font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $background-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1.125rem;
  z-index: 1000;

  .menu-toggle {
    background: none;
    border: none;
    cursor: pointer;
    display: none;

    .bar {
      background-color: $background-dark;
      height: 3px;
      width: $size;
      margin: 5px 0;
      display: block;
    }
  }
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 20px;

  li:last-child {
    margin-right: 0;
  }
}

.link {
  font-family: $font-family;
  font-size: $size;
  color: $background-dark;
}

@media (max-width: 768px) {
  .header {
    padding-left: 0.5rem;
  }

  .menu-toggle {
    display: block !important;
  }

  .nav {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $background-light;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    display: none;

    &.show {
      display: flex;
    }

    .nav-list {
      flex-direction: column;
      width: 100%;
      gap: 0;
      border-top: 1px solid $background-dark;

      li {
        padding: 0.5rem;
        width: 100%;
        text-align: left;
      }
    }
  }
}