@import 'global';
$spacing-lg: 3rem;
$spacing-md: 2rem;
$spacing-sm: 1rem;

.chart {
  display: grid;
  grid-template-columns: 1fr;
}

.charts-container, .chart-label-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $spacing-sm;
  padding: $spacing-sm;
}

.chart-title, .chart-label span, 
.chart-label select, 
.no-data-chart {
  font-weight: bold;
  font-family: $font-family;
}

.chart-title {
  justify-content: center;
  align-items: center;
  display: flex;
}

.chart-label span {
  margin-right: 0.5rem;
}

.chart-label select {
  color: $link-color;
  background-color: $background-color;
  cursor: pointer;
}

.recharts-legend-icon, 
.recharts-legend-item-text {
  display: none;
}

.no-data-chart {
  margin-top: 1rem;
  border: 2px solid $background-color;
  padding: $spacing-lg;
}

@media (max-width: 1024px) {
  .charts-container {
    flex-direction: column;
    
    .recharts-text tspan {
      display: none;
    }
  }

}