@import 'global';
$content-border-radius: 5px;
$min-height: 100vh;
$spacing: 1rem;
$border: 2px solid #000;

.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: $spacing;
  padding: $spacing;
  min-height: $min-height;

  .search-list {
    margin: $spacing;
  }

  .search-button, .search-text {
    font-weight: bold;
    font-family: $font-family;
    color: $text-color;
    margin: $spacing;
  }
  
  .search-button {
    border: $border;
    margin: $spacing;
    padding: $spacing;
    border-radius: $content-border-radius;
  }
  
  .icon-img {
    padding: $spacing;
    margin: $spacing;
  }
}

@media (max-width: 1024px) {
  .search-icon {
    display: none;
  }
}